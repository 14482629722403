import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/Layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1>{`Uses`}</h1>
    <p>{`There is a lot going on underneath what seems like a simple website.
Here are a handful of the tools being used`}</p>
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`PostCSS`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`React`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Gatsby`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`MDX`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`GitHub`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Theme UI`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Emotion`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`ZEIT's now`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Micro`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Yarn`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Lerna`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Victory`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Wayback Machine`}</li>


      <li {...{
        "parentName": "ul"
      }}>{`Feather icons`}</li>

    </ul>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      